import React, { useRef, useEffect, useState } from 'react'
import { Bar, Line, Pie } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'

const PieChart = ({ chartData }) => {

    console.log(chartData, "chartData")

    return (
        <div className='chart-container'>

     <Pie data={chartData} id="pie-chart"/>
        </div>
          )
    
}
export default PieChart;