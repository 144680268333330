const permisson = "permission";
const audience = "audience";

export const ADD_USER_ROLE = `${permisson}/addRoles`;
export const GET_USER_ROLE = `${permisson}/getRoles/findAll`;
export const UPDATE_USER_ROLE = `${permisson}/updateRoles`;
export const DELETE_USER_ROLE = `${permisson}/deleteRoles`;
export const GET_USER_ROLE_BY_ID = `${permisson}/getRoles/findOne`;
export const ADD_HUBSPOT_TO_Contact = `contactUs/addContactToHubspot`;
export const ADD_MAIL_CHIMP_TO_Contact = `contactUs/addMailChimpContacts`
export const ADD_HUBSPOT_TO_ATANDEES = `audience/addAudienceToHubspot`
export const ADD_MAILCHIMP_TO_ATANDEES = `audience/addMailChimpAudience`

export const ADD_USER = `${audience}/addaudience`;
export const GET_ALL_USERS = `${permisson}/getSubAdmin/findAll`;
export const GET_USER_BY_ID = `${permisson}/getSubAdmin/findOne`;
export const UPDATE_USER_BY_ID = `${audience}/updateaudience`;
export const DELETE_USER_BY_ID = `${audience}/deleteaudience`;
