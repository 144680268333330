import React, { useContext, useEffect, useState } from "react";

import { FaUserAlt } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { FiUsers } from "react-icons/fi";

import dashboardbg from "../Assets/bg.png";
import { FiMic ,FiStar} from "react-icons/fi";
import { BsDot } from "react-icons/bs";
import profileImg from "../Assets/profile.avif";
import { DataContext } from "../../context/DataState";
import { Link } from "react-router-dom";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import RightSidebar from "./RightSidebar";
import LineChart from './Charts/LineChart';
import BarChart from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import PolarChart from "./Charts/PolarCgart";
import dummyImage from "../Assets/dummyimage.jpg"
import { AiOutlineEye } from "react-icons/ai";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const Dashboard = () => {
  const { AllCountApi, allCount, getNotificationApi, getAgendaDatesApi,
    agendaDatesState, getAgendaSessionState, getSessionApi, startDate,getAudienceById,adminInfo ,getDummyGraphDataApi,graphData,globalLoader} = useContext(DataContext);

  const [toggle, setToggle] = useState(startDate)
  const [dataGraph,setDataGraph]=useState([])
  
  const arr=[10,20,30,40,50,60,70,80,90,100]

  const timeconversion = (date) => {
    let nDate = String(date)

    return nDate.slice(11, 16)
  }




  const handleDates = (date) => {

    if (toggle === date) {
      setToggle(!null);
      return false
    }
    // 2009-11-10

    setToggle(date);

    getSessionApi(date)

  }
  useEffect(() => {
    if (startDate !== null) {
      setToggle(startDate)
    }
  }, [startDate !== null])

  useEffect(() => {
    getAgendaDatesApi()
    getAudienceById()
    AllCountApi();
    getDummyGraphDataApi()
   
  }, []);

  useEffect(() => {
    if (startDate !== null)
      getSessionApi(startDate)
    console.log(startDate, "start date data")
  }, [startDate])
console.log(graphData,"graphData")
  const userData = {
    // data: [
    //   {
    //     id: 1,
    //     year: 2016,
    //     userGain: 80000,
    //     userLost: 823,
    //   },
    //   {
    //     id: 2,
    //     year: 2017,
    //     userGain: 45677,
    //     userLost: 345,
    //   },
    //   {
    //     id: 3,
    //     year: 2018,
    //     userGain: 78888,
    //     userLost: 555,
    //   },
    //   {
    //     id: 4,
    //     year: 2019,
    //     userGain: 90000,
    //     userLost: 4555,
    //   },
    //   {
    //     id: 5,
    //     year: 2020,
    //     userGain: 4300,
    //     userLost: 234,
    //   },
    // ],
    backgroundColor: [
      'rgba(255, 99, 132, 0.2)',
      'rgba(255, 159, 64, 0.2)',
      'rgba(255, 205, 86, 0.2)',
      'rgba(75, 192, 192, 0.2)',
      'rgba(54, 162, 235, 0.2)',
      'rgba(153, 102, 255, 0.2)',
      'rgba(201, 203, 207, 0.2)'
    ],
    borderColor: [
      'rgb(255, 99, 132)',
      'rgb(255, 159, 64)',
      'rgb(255, 205, 86)',
      'rgb(75, 192, 192)',
      'rgb(54, 162, 235)',
      'rgb(153, 102, 255)',
      'rgb(201, 203, 207)'
    ]
  }

  const [barChartData, setBarChartData] = useState({
    labels: graphData.length && graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Testing",
      data: graphData.length && graphData[0]?.data?.map((element) => element.y.data),
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 1,
    }]
  })

  const [lineChartData, setLineChartData] = useState({
    labels: graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Speaker Data",
      data: graphData[0]?.data?.map((element) => element.y.data),
    
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 1,
      tension: 0.1
    }]
  })

  

  const [pieChartData, setPieChartData] = useState({
    labels: graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Testing",
      data: graphData[0]?.data?.map((element) => element.y.data),
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 2,
    }]
  })

  const [polarChartData, setPolarChartData] = useState({labels: graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Testing",
      data: graphData[0]?.data?.map((element) => element.y.data),
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 2,
    }]})

const polarFunction=()=>{
  setBarChartData({
    labels: graphData.length && graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Testing",
      data: graphData.length && graphData[0]?.data?.map((element) => element.y.data),
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 1,
    }]
  })
  setLineChartData({
    labels: graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Speaker Data",
      data: graphData[0]?.data?.map((element) => element.y.data),
    
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 1,
      tension: 0.1
    }]
  })
setPolarChartData({
    
  labels: graphData[0]?.data?.map((data) => data.x.month),
  datasets: [{
    label: "Testing",
    data: graphData[0]?.data?.map((element) => element.y.data),
    backgroundColor: userData.backgroundColor,
    borderColor: userData.borderColor,
    borderWidth: 2,
  }]})
  setPieChartData({
    labels: graphData[0]?.data?.map((data) => data.x.month),
    datasets: [{
      label: "Testing",
      data: graphData[0]?.data?.map((element) => element.y.data),
      backgroundColor: userData.backgroundColor,
      borderColor: userData.borderColor,
      borderWidth: 2,
    }]
  })
}  
useEffect(()=>{
  console.log(graphData,"graphadtata")

if( graphData.length>0)
{
console.log("hellofghj")
polarFunction()
// setDataGraph(graphData)
}

},[graphData.length>0])

console.log(graphData,"graphData")

  
  return (
    <>
      <div className=" w-full flex h-screen overflow-x-scroll">
        <div className="w-[100%] px-4 pt-5">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div className="flex justify-between items-center">
              <div className="">
                  <h1 className="text-theme-black mt-0 text-xl font-semibold">
                    Dashboard
                  </h1>
                  </div>
                  <div className="flex gap-3 items-center">
                    <img src={adminInfo?.image==undefined|| adminInfo?.image==""? dummyImage :adminInfo?.image} alt="dummyPhoto" className="w-12 h-12 rounded-full" />
                    <h1 className="text-xs font-bold text-[#202223]"> {`Welcome, ${adminInfo?.Name}`}</h1>
                  </div>
              </div>
              
 {globalLoader? <GlobalLoader/>:
  <div>
  <div className="grid grid-cols-4 gap-x-4 5xl:pt-[21px] 4xl:pt-[22px] 3xl:pt-[22px] 2xl:pt-[22px]  ">
                  <Link to="/speaker_list">
                    <div className="flex bg-[#DBE6FE]  gap-[24px] 3xl:h-[130px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                            Speakers
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countSpeakers}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24}/>
                        </div>
                      </div>
                    </div>
                  </Link>
  
                  <Link to="/agenda-list">
                    <div className="flex bg-[#FDE2DB]  2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                            Agendas
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countAgendas}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/sponsors">
                    <div className="flex bg-[#E3F5FF]  2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                           Sponsors
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countSponsors}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
  
                  <Link to="/attendees">
                    <div className="flex bg-[#E1F5E8]  5xl:h-[159px]  4xl:h-[135px]   2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                            Attendees
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countAudiences}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
                  
                  <Link to="/exhibitors">
                    <div className="flex mt-3  bg-[#E3F5FF]  5xl:h-[159px]  4xl:h-[135px]   2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                           Exhibitors
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countExhibitors}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/polls">
                    <div className="flex mt-3 bg-[#DBE6FE]  5xl:h-[159px]  4xl:h-[135px]   2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                          Polls
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countPolls}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/faq">
                    <div className="flex mt-3  5xl:h-[159px] bg-[#E1F5E8] 4xl:h-[135px]   2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                         FAQs
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countFaqs}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <Link to="/socialwall">
                    <div className="flex mt-3 bg-[#FDE2DB] 5xl:h-[159px]  4xl:h-[135px]   2xl:h-[110px] 3xl:h-[130px] gap-[24px] items-center justify-center rounded-2xl">
                      <div className="flex justify-between w-full m-5">
                        <div>
                          <p className="font-semibold text-xs leading-4">
                        Posts
                          </p>
                          <span className="font-semibold text-3xl">
                            {allCount?.countPost?.length}
                          </span>
                        </div>
                        <div className="mt-2">
                          <AiOutlineEye size={24} />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="flex justify-between  pt-8 ">
                  <BarChart chartData={barChartData} />
                  <LineChart chartData={lineChartData} />
  
                </div>
                <div className=" flex justify-between mt-16 ">
                  <PieChart chartData={pieChartData} />
                  <PolarChart chartData={polarChartData}/>
                </div>
  
                <div className="bg-white w-full mt-4  h-[70vh] 3xl:h-[74vh] rounded-2xl ">
                  <div>
                    <ul className="flex gap-2 p-4 overflow-x-scroll scrollbar-none w-[55vw]">
                      {
                        agendaDatesState?.map((item, index) => {
                          return (
                            <>
                              <li className={`${toggle === item ? "bg-theme-light-color text-theme-color" : ""} rounded-lg  p-2 font-medium 3xl:text-xs cursor-pointer whitespace-nowrap`} key={index} id={index} onClick={() => { handleDates(item) }} >
                                {item?.slice(0, 10)}
                              </li>
                            </>
                          )
                        })
                      }
  
  
  
  
                      {/* <li className="text-[#4B4B4B] p-2 font-medium 3xl:text-xs">
                        15 June 2023
                      </li>
                      <li className="text-[#4B4B4B]  p-2 font-medium 3xl:text-xs">
                        16 June 2023
                      </li> */}
                    </ul>
                  </div>
                  <div className="border border-[#0000001A] rounded-2xl h-[370px] 3xl:h-[450px] mx-4 mt-1 ">
                    <div className="flex justify-between items-start mx-3 mt-3">
                      <div>
                        <p className="font-semibold text-base text-[#202223] ">
  
  
                          {`Session schedule for ${new Date(toggle)?.toLocaleString('default', { month: 'long' })} ${toggle?.slice(8, 10)}, ${toggle?.slice(0, 4)}`}
                        </p>
                        <p className="font-normal text-sm text-[#4B4B4B]">
                          Create and edit the session{" "}
                        </p>
                      </div>
                      <div>
                        <Link to="/new_session">
                          <button className="bg-theme-color font-medium text-sm p-2 text-white rounded">
                            Add new session
                          </button>
                        </Link>
                      </div>
                    </div>
                    <ul className="mt-6 overflow-y-scroll scrollbar-none h-[280px] 3xl:h-[360px] w-full">
                      {
                        getAgendaSessionState.length === 0 ? <div className="flex justify-center"><h1 className="text-[#4B4B4B] font-light mt-10 w-auto">No session for this date...</h1></div> :
                          getAgendaSessionState.map((item, index) => {
                            return (
                              <li className="flex gap-3 mx-3" key={index}>
                                <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                                  {item?.startTime !== undefined ? timeconversion(item?.startTime) : ""}
                                </span>
  
                                <div className=" border-t border-[#BDBDBD] w-full">
                                  <div className="bg-[#DBE6FE] my-3 rounded-lg">
                                    <div className="px-4 py-2">
                                      <p className="text-[#202223] font-medium text-sm">
                                        {" "}
                                        {item?.agenda_name}
                                      </p>
                                    </div>
                                    <div className="flex mt-1 px-4 pb-2 ">
                                      {/* <img
                                  src={profileImg}
                                  alt="profile"
                                  className="rounded-full w-4 h-4 mr-1"
                                /> */}
  
                                      {
  
                                        ((item?.speaker)).map((element, index) => {
                                          return (
                                            <div className="relative right-2 " key={index}>
  
                                              <img
                                                src={element?.image}
                                                className="w-4 h-4  rounded-full object-fill "
                                              />
                                            </div>
                                          )
  
                                        })
                                      }
  
  
  
  
                                      <span className="font-normal text-sm text-[#4B4B4B]">
                                        {item?.startTime !== undefined ? timeconversion(item?.startTime) : ""} - {item?.startTime !== undefined ? timeconversion(item?.endTime) : ""}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          })
                      }
  
  
                      {/* <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          11 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#E1F5E8] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
  
                      <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          12 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#FDE2DB] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          12 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#FDE2DB] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          12 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#FDE2DB] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          12 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#FDE2DB] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          12 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#FDE2DB] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className="flex gap-3 mt-2 mx-3">
                        <span className="font-medium text-sm text-[#202223] whitespace-nowrap">
                          12 Am
                        </span>
  
                        <div className=" border-t border-[#BDBDBD] w-full">
                          <div className="bg-[#FDE2DB] my-3 rounded-lg">
                            <div className="px-4 py-2">
                              <p className="text-[#202223] font-medium text-sm">
                                {" "}
                                Ux testing with senior developers
                              </p>
                            </div>
                            <div className="flex mt-1 px-4 pb-2 ">
                              <img
                                src={profileImg}
                                alt="profile"
                                className="rounded-full w-4 h-4 mr-1"
                              />
                              <span className="font-normal text-sm text-[#4B4B4B]">
                                9 AM - 10 AM
                              </span>
                            </div>
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
  </div>
 }

            
            </div>
          </div>
        </div>
        {/* <RightSidebar /> */}
      </div>
    </>
  );
};
export default Dashboard;
