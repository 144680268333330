import React, { useState ,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { DataContext } from '../../context/DataState'
import { ToastContainer } from 'react-toastify'
import { FaArrowLeft } from "react-icons/fa";


const AddFaq = () => {
  const {AddFaqApi,getFaqApi,faqList,disableSave,isSpinner}=useContext(DataContext);
  const [state,setState]=useState({
    FAQText:'',
    FAQanswer:'',
  })
  const buttonDisabled=(state.FAQText !=='' && state.FAQanswer !=='')
  
  const [count,setCount]=useState(0)

  const handleSubmit=()=>{
    if(!isSpinner)
    AddFaqApi(state);
  }
 
  const handleChange=(e)=>{
    setState({...state,FAQanswer:e.target.value})
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  }

  useEffect(()=>{
  //  getFaqApi()
   faqList.filter((element)=>{
    return
   })
  },[])
  return (
    <>
    <ToastContainer/>
    <div className="w-full  px-7 py-2 overflow-y-scroll h-screen space-y-4">
      <div className='pt-4'>
        <div className='flex items-center gap-4'>
        <Link to="/faq">
              <div className='shadow-xs border p-2 rounded  '>

                < FaArrowLeft className='text-[20px] text-[#5C5F62]' />

              </div>
            </Link>
      <h1 className="text-xl text-[#202223] font-semibold   ">
            FAQ Information
           </h1>
        </div>
      <div className='flex flex-col bg-white mt-5 border-[#D1D9E7] p-3 rounded-lg'>
      <div className=''>
            <h3 className='pb-2'>FAQ details</h3>
            <hr/>
            <form className='py-3'>
              <div>
              <label className="block mb-2 text-sm" typeof='heading' htmlFor='heading'>
             Heading *  </label>
              <input
                type="name"
                className="mt-1 block w-full px-3 py-2 bg-white placeholder:text-slate-400 h-9 border  rounded-md text-sm  placeholder-slate-400  focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-gray-300"
                value={state.FAQText} onChange={(e)=>{setState({...state,FAQText:e.target.value})}}
                placeholder='Enter your question'
              />
           
              </div>

              <div className='mt-2'>
              <label className="block mb-2 text-sm" typeof='description' htmlFor='description'>
              Description *
              <textarea
                type="name"
                rows={"4"}
                className="mt-1 block w-full  px-3 py-1 bg-white border placeholder:text-slate-400   rounded-md text-sm  placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-gray-200 " 
                value={state.FAQanswer}   onChange={(e)=>{handleChange(e)}}  
                placeholder='Enter description for your question'  maxLength={2000}         
              />
              </label>

              </div>
            
            
               <div className='flex justify-between mt-1'>
               <span className='text-sm text-[#6D7175]'>Provide description about heading</span>
              <span className=" text-xs ">{count}/2000</span>
               </div>
              
            
            </form>
            <div className='space-x-3 flex gap-3'>
                <Link to="/faq">
                <button className='border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded'>Back</button>
                </Link>
                <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleSubmit();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
            </div>
           </div>
      </div>
           
      </div>
    </div>
    </>
  )
}

export default AddFaq;
