import React from "react";
import googleAnalyticsImage from "../Assets/Google logo.png"
import mailChimp from "../Assets/layer1.png";
import sendGrid from "../Assets/sendgrid logo.png"
import razorpay from "../Assets/Razorpay logo.png";
import stripe from "../Assets/Vector (3).png"

const Settings=()=>{
  const analayticsArray=[{
    h3:"Google Analytics",
    image:googleAnalyticsImage,
    description:"Gain valuable insights into your website traffic, user behavior, and marketing campaigns with powerful analytics integration."
  },
  {
    h3:"Mail Chimp",
    image:mailChimp,
    description:"Streamline your email marketing efforts by seamlessly integrating your mailing lists and campaigns with Mailchimp integration."
  },
  {
    h3:"Send Grid",
    image:sendGrid,
    description:"Ensure reliable email delivery and tracking with the scalable and feature-rich Sendgrid email service integration."
  },
  {
    h3:"Razorpay",
    image:razorpay,
    description:"Accept online payments effortlessly with the secure and reliable Razorpay payment gateway integration."
  },
  {
    h3:"Stripe",
    image:stripe,
    description:" Facilitate online payments and manage transactions efficiently with the user-friendly Stripe payment gateway integration."
  }
]
  return(
    <>
     <div className="w-full px-7 py-2 overflow-y-scroll scrollbar-thin h-screen space-y-4">
        <div className="pt-3 flex gap-4 items-center">
          <h1 className="text-xl text-[#202223] font-semibold">Integration</h1>
         
         
         
        </div>
        <div className="flex justify-between gap-4 flex-wrap">
          {
            analayticsArray.map((item)=>{
              return(
                <div className="w-[49%] bg-white p-4 border-[#DADADA] border shadow-md rounded ">
                <div className="flex flex-col gap-8">
                  <div className="flex gap-6">
                    <img src={item.image} alt="" />
                    <div className="flex-col">
                      <h3 className="text-[#202223] text-sm font-semibold ">{item.h3}</h3>
                      <p className="text-[10px] font-normal text-[#4B4B4B] leading-[16px]">{item.description}</p>
                    </div>
                  </div>
                  <div>
            <label for="hs-trailing-button-add-on" class="sr-only">Label</label>
            <div class="flex rounded-md border shadow-sm">
              <input type="text" id="hs-trailing-button-add-on" placeholder="Enter Key" name="hs-trailing-button-add-on" className="py-3 px-4 block w-full border-[#BABFC3] shadow-sm rounded-sm  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9 "/>
              <button type="button" class="py-3 px-2 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-sm border border-transparent  text-white bg-theme-color disabled:opacity-50 disabled:pointer-events-none">
               Connected
              </button>
            </div>
          </div>
                </div>
          
               </div>
              )
            })
          }
        </div>
    
      
         
     
      </div>
    </>
  )
}
export default Settings;
