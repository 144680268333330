import React, { useRef, useEffect, useState } from 'react'
import { Bar, Line, Pie, PolarArea } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'

const PolarChart = ({ chartData }) => {

    console.log(chartData, "chartData")

    return (
        <div className='chart-container'>
    <PolarArea data={chartData} id="polar-chart" />
        </div>
          )
    
}
export default PolarChart;