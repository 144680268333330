import React, { useContext, useEffect, useState } from "react";
import { FaSearch, FaArrowLeft, FaSortUp, FaSortDown } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { FiTrash, FiEdit2, FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";
import NoContactModal from "./NoContactModal";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import ContactListModal from "./ContactListModal";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import SendEmailModal from "./SendEmailModal";
import Pagination from "../Pagination/Pagination";
import { Tooltip } from "react-tooltip";
import fetchUtil from "../../utils/fetch.util";
import { ADD_HUBSPOT_TO_Contact, ADD_MAIL_CHIMP_TO_Contact } from "../../constants/apiEndPoints";
import { toast } from "react-toastify";
import ExportXLSButton from "../atoms/ExportXLSButton";
import TableHeader from "../atoms/TableHeader";

const contactsTableHeader = [
  { label: "First name", value: "firstName", sorting: true },
  { label: "Last name", value: "lastName", sorting: true },
  { label: "Email", value: "email" },
  { label: "Designation", value: "position" },
  { label: "Phone number", value: "phoneNumber" },
  { label: "Actions", value: "actions" },
];

const AddContactList = () => {
  const {
    getCreateContactListApi,
    createContactList,
    checkResponse,
    globalLoader,
    isContactList,
    setIsContactList,
    setDeleteBtnId,
    deleteBtnId,
    setDeleteBtn,
    deleteBtn,
    deleteContactApi,
    setContactListId,
    isSendMail,
    setIsSendMail,
    setCreateContactList,
    totalCount,
    pageCount,
    currentPage,
    setCurrentPage,
    createContactListPaginationApi,
    searchCreateContactListApi,
  } = useContext(DataContext);
  const { contactId } = useParams();
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [contactListIds, setContactListIds] = useState("");

  useEffect(() => {
    if (selectedItems.length !== 0) {
      dataTransform(selectedItems);
    } else {
      dataTransform(createContactList);
    }
  }, [createContactList, selectedItems]);

  const dataTransform = (data) => {
    const transformedData = data.map((item) => {
      setContactListIds(item.contactListId);
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        phoneNumber: item.phoneNumber,
      };
    });
    setExportData([...transformedData]);
  };

  const exportHubSpot = () => {
    fetchUtil
      .POST(`${ADD_HUBSPOT_TO_Contact}/${contactListIds}`, {
        selectedContacts: exportData,
      })
      .then((resp) => {
        console.log(resp);
        toast.success(resp.message);
        setTimeout(() => {}, 100);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  };

  const exportMailChimp = ()=>{
    fetchUtil
      .POST(`${ADD_MAIL_CHIMP_TO_Contact}/${contactListIds}`, {
        selectedContacts: exportData,
      })
      .then((resp) => {
        console.log(resp);
        toast.success(resp.message);
        setTimeout(() => {}, 100);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      });
  }

  const checkboxHandler = (e, item) => {
    const isSelected = e.target.checked;

    let value = item;

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((element) => {
          return element._id !== value._id;
        });
      });
    }
  };

  const checkAllboxHandler = (e) => {
    const isSelected = e.target.checked;
    let newArray = [];
    if (isSelected) {
      newArray = createContactList.map((item) => {
        return item;
      });
      setSelectedItems([...newArray]);
    } else {
      setSelectedItems([]);
    }
  };

  const location = useLocation();
  const isContactActive = location.state.isContactActive;
  const id = location.state.id;
  const name = location.state.name;

  const onKeyUpHandler = (e) => {
    searchCreateContactListApi(id, e.target.value, currentPage);
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...createContactList].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setCreateContactList(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };

  useEffect(() => {
    setCurrentPage(1);
    createContactListPaginationApi(id, 1, limit);
  }, []);

  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteContactApi}
          deleteId={deleteBtnId}
          id={id}
        />
      ) : (
        ""
      )}
      {isSendMail ? (
        <SendEmailModal
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      ) : (
        <></>
      )}
      <ToastContainer />
      <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
        <div className="flex  items-center pt-3">
          <Link to={"/contactlist"}>
            <div className="w-8 pt-1">
              <Link to={"/contactlist"}>
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </Link>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">{name} </h1>
          </div>
        </div>
        <div className=" flex justify-between space-x-2 pt-4 items-start flex-wrap">
          <div className="flex gap-1">
            <button
              className="border   border-[#BABFC3] disabled:opacity-40 px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
              disabled={createContactList.length === 0}
              onClick={() => {
                setIsSendMail(true);
              }}
            >
              Send Email
            </button>
            <Link
              to={"/import-contacts"}
              state={{
                isContactActive: isContactActive,
                id: id,
                name: name,
              }}
            >
              <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                Import Contacts
              </button>
            </Link>
            <ExportXLSButton
              tableHead={contactsTableHeader}
              tableData={createContactList}
              nameOfFile="contacts_table"
            />
            <Link
              to={"/createcontact"}
              state={{
                isContactActive: isContactActive,
                id: id,
                name: name,
              }}
            >
              <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                Add Contact
              </button>
            </Link>
            <button
              className="border   border-[#BABFC3] disabled:opacity-40 px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
              disabled={createContactList.length === 0}
              onClick={() => exportHubSpot()}
            >
              Export to HubSpot
            </button>
            <button
              className="border   border-[#BABFC3] disabled:opacity-40 px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
              disabled={createContactList.length === 0}
              onClick={() => {
                exportMailChimp()
              }}
            >
              Export to MailChimp
            </button>
          </div>
          <div className="">
            <div className="flex">
              <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
              <input
                type="text"
                placeholder="Search"
                className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  onKeyUpHandler(e);
                }}
              />
            </div>
          </div>
        </div>

        {createContactList?.length === 0 && checkResponse === true ? (
          <NoContactModal
            isContactActive={isContactActive}
            id={id}
            name={name}
          />
        ) : globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
            <table className="w-full table-auto bg-white text-start shadow-md  rounded-lg ">
              <thead className="cursor-pointer">
                <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                  <th className="px-4 py-2 rounded">
                    <input
                      type="checkbox"
                      name="firstName"
                      id="firstName"
                      checked={
                        selectedItems.length === createContactList.length &&
                        createContactList.length != 0
                      }
                      onChange={(e) => {
                        checkAllboxHandler(e);
                      }}
                    />
                  </th>
                  <TableHeader
                    tableHeadData={contactsTableHeader}
                    tableBodyData={createContactList}
                    setSortedData={setCreateContactList}
                  />
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                {createContactList?.map((item) => {
                  return (
                    <tr className="border-t  text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                      <td className="py-2 px-4">
                        <input
                          type="checkbox"
                          name="name"
                          id="name"
                          checked={selectedItems
                            .map((element) => {
                              return element._id;
                            })
                            .includes(item._id)}
                          // value={item._id}
                          onChange={(e) => {
                            checkboxHandler(e, item);
                          }}
                        />
                      </td>
                      <td className="py-2 px-4">{item?.firstName}</td>
                      <td className=" py-2 px-4 ">{item?.lastName}</td>

                      <td className=" py-2 px-4">{item?.email}</td>
                      <td className=" py-2 px-4">{item?.position}</td>
                      <td className=" py-2 px-4">{item?.phoneNumber}</td>

                      <td className="py-2 px-4">
                        <div className="flex items-center  justify-center  gap-2 text-black ">
                          <Link
                            to={"/view-contact"}
                            state={{
                              item: item,
                              isContactActive: isContactActive,
                              id: id,
                              name: name,
                            }}
                          >
                            <AiOutlineEye
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="View"
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] "
                            />
                            <Tooltip
                              place="top"
                              id="my-tooltip"
                              className="custom-tooltip"
                            />
                          </Link>
                          <Link
                            to={`/update-contact/${item?._id}`}
                            state={{
                              item: item,
                              isContactActive: isContactActive,
                              id: id,
                              name: name,
                            }}
                          >
                            <FiEdit2
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content="Edit"
                              className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            />
                            <Tooltip
                              place="top"
                              id="my-tooltip"
                              className="custom-tooltip"
                            />
                          </Link>
                          <FiTrash
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="Delete"
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            onClick={() => {
                              setDeleteBtn(true);
                              setDeleteBtnId(item?._id);
                              setContactListId(id);
                            }}
                          />
                          <Tooltip
                            place="top"
                            id="my-tooltip"
                            className="custom-tooltip"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {createContactList.length > limit && !checkResponse && (
              <div className="flex justify-between items-start ">
                <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount} results`}</span>

                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={createContactListPaginationApi}
                  id={id}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default AddContactList;
