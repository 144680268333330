import React, { useState } from 'react';
import { FaSortUp, FaSortDown } from "react-icons/fa";

const TableHeader = (props) => {
  const { tableHeadData, tableBodyData, setSortedData } = props;
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }
    const sortedData = [...tableBodyData].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setSortedData(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };

  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      console.log(direction)
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    else return direction === "desc" ? "text-black" : "text-gray-500";
  };


  return (
    <>
      {tableHeadData.map((th) => (
        <th className={`px-4 py-2 first:rounded-tl-lg last:rounded-tr-lg ${!!th.sorting && 'relative'}`}>
          <div className="flex">
            <span className="mr-2">{th.label}</span>
            {!!th.sorting &&
              <span>
                <FaSortUp
                  className={`top-[30%] absolute ${getSortArrowClassName(th.value, "asc")}`}
                  onClick={() => sortData(th.value)} />
                <FaSortDown
                  className={`top-[40%] absolute ${getSortArrowClassName(th.value, "desc")}`}
                  onClick={() => sortData(th.value)} />
              </span>
            }
          </div>
        </th>))}
    </>
  )
}

export default TableHeader
