import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import Pagination from "../Pagination/Pagination";
import SpeakerModal from "./SpeakerDetails";
import NoDataModal from "../NoDatatModal/NoDataModal";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { Tooltip } from "react-tooltip";
import exportToXLS from "../../utils/exportXLS";
import ExportXLSButton from "../atoms/ExportXLSButton";
import TableHeader from "../atoms/TableHeader";

const speakerTableHeader = [
  { label: "Photo", value: "image" },
  { label: "Title", value: "title", sorting: true },
  { label: "Full name", value: "speaker_firstname", sorting: true },
  { label: "Position", value: "position", sorting: true },
  { label: "Country", value: "country", sorting: true },
  { label: "Organization", value: "organization", sorting: true },
  { label: "Actions", value: "actions" },
]
const SpeakerList = () => {
  const navigate = useNavigate();
  const {
    setSpeakers,
    speakers,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteSpeakerApi,
    searchSpeakerApi,
    speakerPaginationApi,
    totalCount,
    pageCount,
    currentPage,
    setCurrentPage,
    speakerModal,
    setSpeakerModal,
    checkResponse,
  } = useContext(DataContext);
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);

  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  // const [isSorted, setIsSorted] = useState(false);

  const onKeyUpHandler = (e) => {
    searchSpeakerApi(e.target.value, currentPage);
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...speakers].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setSpeakers(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };

  const handlePreview = () => {
    setSpeakerModal(true);
  };

  useEffect(() => {
    setCurrentPage(1);
    speakerPaginationApi(1, limit);
  }, []);
  useEffect(() => {
    speakers.map((item) => {
      return {};
    });
  });

  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteSpeakerApi}
          deleteId={deleteBtnId}
          currentPage={currentPage}
          limit={limit}
          secondResultCount={secondResultCount}
          setSecondResultCount={setSecondResultCount}
        />
      ) : (
        ""
      )}

      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <h1 className="text-xl text-[#202223] font-semibold">
              Speaker Profile
            </h1>
            <div className=" flex justify-between pt-4 ">
              <div className="flex ">
                <Link to="/add_speaker">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add New Speaker
                  </button>
                </Link>
                <ExportXLSButton
                  className='ml-4'
                  tableHead={speakerTableHeader}
                  tableData={speakers}
                  nameOfFile='speaker_table'
                />
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/*--------- table list--------- */}

          {
            speakers?.length === 0 && checkResponse === true ? <NoDataModal /> :
              globalLoader ?
                <GlobalLoader /> : (
                  <>
                    <table className="w-full table-auto bg-white shadow-md rounded-lg  ">
                      <thead className="cursor-pointer">
                        <tr className="  text-left  bg-theme-light-color text-theme-color text-xs font-normal  ">
                          <TableHeader
                            tableHeadData={speakerTableHeader}
                            tableBodyData={speakers}
                            setSortedData={setSpeakers}
                          />
                        </tr>
                      </thead>
                      <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                        {speakers?.map((item) => (
                          <tr className="border-t text-left text-sm text-[#202223] font-normal hover:shadow-tableRowsShadow  cursor-pointer" key={item?._id}>
                            <td className="px-4 py-2">
                              <img
                                src={item?.image}
                                className="w-8 h-8 rounded-[2px]" />
                            </td>
                            <td className="px-4 py-2">{item?.title}</td>
                            <td className="px-4 py-2">{`${item?.speaker_firstname} ${item?.speaker_lastname}`}</td>
                            <td className="px-4 py-2">{item?.position}</td>
                            <td className="px-4 py-2">{item?.country}</td>
                            <td className="px-4 py-2">{item?.organization}</td>
                            <td className="px-4 py-2">
                              <div className="flex items-center  justify-center text-black ">
                                <Link to={"/speakeroverview-view"} state={item}>
                                  <HiOutlineChartSquareBar size={30} data-tooltip-id="my-tooltip" data-tooltip-content="Report" className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' />
                                  <Tooltip place="top" id="my-tooltip" className="custom-tooltip" />
                                </Link>
                                <Link to={`/speaker/${item?._id}`} state={item}>
                                  <AiOutlineEye onClick={() => {
                                    handlePreview()
                                    setPreviewId(item?._id);
                                  }}
                                    data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                    className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                  <Tooltip place="top" id="my-tooltip" className="custom-tooltip" />
                                </Link>
                                <Link to={`/update_speaker/${item?._id}`} state={item}>
                                  <FiEdit2
                                    data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                    className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                                  <Tooltip place="top" id="my-tooltip" className="custom-tooltip" />
                                </Link>
                                <FiTrash
                                  data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                                  className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                                  onClick={() => {
                                    setDeleteBtn(true);
                                    setDeleteBtnId(item?._id);
                                  }}
                                />
                                <Tooltip place="top" id="my-tooltip" className="custom-tooltip" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {
                      !checkResponse && <div className="flex justify-between items-center ">
                        <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount === 1 ? totalCount : secondResultCount} of ${totalCount} results`}</span>
                        <Pagination
                          firstResultCount={firstResultCount}
                          setFirstResultCount={setFirstResultCount}
                          secondResultCount={secondResultCount}
                          setSecondResultCount={setSecondResultCount}
                          limit={limit}
                          api={speakerPaginationApi}
                        />
                      </div>
                    }
                  </>
                )}
        </div>
      </div>
    </>
  );
};

export default SpeakerList;
