import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import TimeLine from "./TimeLine"
import { FiCamera } from "react-icons/fi";
import Activity from "../Socialwall/Activity";
import { DataContext } from "../../context/DataState";
import dummyImage from "../Assets/dummyimage.jpg"
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { ToastContainer } from "react-toastify";
import DisablePosts from "./DisablePosts";

const Socialwall = () => {
  const {getAllPostsApi,allPostsList,getAudienceById,
    adminInfo,globalLoader}=useContext(DataContext)
  const [status, setStatus] = useState("timeline");
  const [userList,setUserList]=useState([])
  const newObj=allPostsList[0]
  useEffect(()=>{
  //  getAllPostsApi()
   getAudienceById() 
  },[])

  return (
    <>
    <ToastContainer/>
      <div className="w-full overflow-scroll scrollbar-none px-7 py-2 h-screen space-y-4 ">
        <div className="pt-3 ">
          <h1 className="text-xl text-[#202223] font-semibold">Social Wall</h1>
        </div>
        <div className="flex space-x-4">
          <div className="w-[70%] h-[25%]   overflow-y-scroll scrollbar-none">
            <div className=" rounded-md">
              {/* <img
                src={require("../Assets/social wall.png")}
                className="w-full"
              /> */}
              {/* <div className="px-2 mt-4">
                <div className="px-2 w-[20%] float-right relative -top-32 flex items-center border border-[#FFFFFF50] p-1">
                  <FiCamera className="text-white cursor-pointer" />
                  <button className="  bg-transparent px-1 border-none font-normal text-white border text-[15px]">
                    Change Cover{" "}
                  </button>
                </div>
              </div> */}

              <div className=" space-x-5  pb-4">
                <NavLink
                  onClick={(e) => {
                    setStatus("timeline");
                  }}
                >
                  {" "}
                  <button className={`${status=="timeline" ? "text-theme-color" : ""} text-[15px]  transition-all ease-in-out`}>
                    Timeline
                  </button>
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    setStatus("activity");
                  }}
                >
                  {" "}
                  <button className={`${status==="activity" ? "text-theme-color" : ""} text-[15px]  transition-all ease-in-out`}>
                    Activity
                  </button>{" "}
                </NavLink>
                <NavLink
                  onClick={(e) => {
                    setStatus("disabled");
                  }}
                >
                  {" "}
                  <button className={`${status=="disabled" ? "text-theme-color" : ""} text-[15px]  transition-all ease-in-out`}>
                  Disable Post
                  </button>
                </NavLink>
              </div>
            </div>
            
            
             {status=="timeline" && <TimeLine/>} 
             {status=="activity" && <Activity/>} 
             {status=="disabled" && <DisablePosts/>} 
            
            

            

            
          </div>

          {/*---- right-side container ------- */}
          
                <div className="w-[30%] h-[40%] rounded-md bg-white px-3">
                <div className="text-center grid justify-items-center  p-5">
                  <img
                    src={adminInfo?.image=="" ? dummyImage : adminInfo?.image}
                    className="w-[120px] h-[120px] rounded-full object-cover"
                  />
                  <h3 className="text-base text-[#0A0A0A] font-medium">{adminInfo?.Name}</h3>
                  <p className="font-normal text-sm text-[#747474]">
                  {adminInfo?.role}
                  </p>
                </div>
                <div>
                  <form className="">
                    <div className="">
                      <label
                        htmlFor="full-name"
                        className="text-[14px] font-normal text-[#202223] px-1  "
                      >
                        Full Name
                      </label>
                      <input
                    type="text"
                    name="contact-number" 
                    className="w-full border py-1 px-3  bg-[#FAFBFB] border-gray-300  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                    value={adminInfo?.Name}
                    
                  />
                      <p className="py-1 px-3 text-[14px] font-normal">
                       
                      </p>
                    </div>
                    <div className="">
                      <label
                        htmlFor="full-name"
                        className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                      >
                        Business name
                      </label>
                      
                      <input
                    type="text"
                    name="contact-number" 
                    className="w-full border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                    value={adminInfo?.organization}
                    
                  />
                      {/* <input type="text" id="business-name"
                        className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                        name="business-name" 
                        placeholder="shagzz,INC"
                        
                      /> */}
                    </div>
                    <div className="">
                      <label
                        htmlFor="phone-number"
                        className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                      >
                        Phone Number
                      </label>
                     
                     
                      <input
                    type="text"
                    name="contact-number" 
                    className="w-full border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                    value= {adminInfo?.phone}
                    
                  />
                    </div>
                    <div className="">
                      <label
                        htmlFor="email"
                        className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                      >
                        Email
                      </label>
                     
                      <input
                    type="text"
                    name="contact-number" 
                    className="w-full border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                    value=  {adminInfo?.email}
                    
                  />
                      
                    </div>
                    {/* <div className="py-2">
                      <label
                        htmlFor="password"
                        className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                      >
                        Password
                      </label> */}
                      {/* <span className="text-[#fb923c] text-[14px] font-normal float-right">Change password</span> */}
                      {/* <input
                        type="text "
                        id="password"
                        className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                        name="password"
                        placeholder="******"
                      />
                    </div> */}
                  </form>
                  {/* <div className="text-center p-3">
                <button className="text-[14px] font-normal border bg-[#fb923c] px-3 text-white p-1 rounded-md  ">Edit Profile</button>
                </div> */}
                </div>
              </div>
              

          
        </div>
      </div>
    </>
  );
};

export default Socialwall;
