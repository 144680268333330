import React from 'react'
import exportToXLS from '../../utils/exportXLS';

const ExportXLSButton = (props) => {
  const { tableHead, tableData, nameOfFile, className: classes } = props;
  return (
    <button className={`border border-[#BABFC3] px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm ${classes}`}
      onClick={() => exportToXLS(tableHead, tableData, nameOfFile)}
    >
      Export XLS
    </button>
  )
}

export default ExportXLSButton
