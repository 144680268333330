import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import NewRole from './NewRole';
import fetchUtil from '../../utils/fetch.util';
import { DELETE_USER_ROLE, GET_USER_ROLE, GET_USER_ROLE_BY_ID } from '../../constants/apiEndPoints';
import Popover from '../atoms/Popover';
import { toast } from 'react-toastify';
import NoDataModal from '../NoDatatModal/NoDataModal';
import GlobalLoader from '../GlobalLoader/GlobalLoader';

const UserRoles = () => {
  const eventID = JSON.parse(localStorage.getItem("eventId")) || "";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopOpen, setIsPopOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [roleInfo, setRoleInfo] = useState();
  const [roleID, setRoleID] = useState("");
  const [loading, setLoading] = useState(true);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (!isPopOpen) {
      fetchUtil.GET(`${GET_USER_ROLE}/${eventID}`)
        .then((response) => {
          console.log('userRoles-', response)
          setUserRoles(response.Result.docs);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [isPopOpen]);

  const handleCreateRole = () => {
    // setRoleInfo({});
    setTimeout(() => { toggleModal() }, 100);
  }
  const handleRoleAction = (e, id) => {
    setRoleID(id);
    if (e.target.name === 'edit') {
      fetchUtil.GET(`${GET_USER_ROLE_BY_ID}/${id}`)
        .then((response) => {
          console.log(response)
          setRoleInfo(response.Result);
          setTimeout(() => { toggleModal() }, 500);
        })
        .catch((err) => console.error(err))
    }
    if (e.target.name === 'delete') setIsPopOpen(true);
  }
  const handlePopAction = (action) => {
    if (action.target.name === 'no') setIsPopOpen(false);
    if (action.target.name === 'yes') {
      console.log("yes, delete", roleID);
      fetchUtil.DELETE(`${DELETE_USER_ROLE}/${roleID}`)
        .then((response) => {
          console.log(response);
          setLoading(true)
          toast.success("Deleted successfully")
          setTimeout(() => { setIsPopOpen(false) }, 500);
        })
        .catch((err) => console.error(err));
    }
  }
  return (
    <>
      <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-1">
        <div className="pt-3 pb-4 ">
          <div>
            <h1 className="text-xl  font-semibold  ">
              Sponsor
            </h1>
          </div>
          <div className="flex justify-between items-center pt-5 text-sm">
            <div>

              <Link to={"/user-roles"} >
                <button className=" hover:underline text-theme-color underline hover:text-theme-color pr-4">User Roles</button>
              </Link>
              <Link to={"/users"} >
                <button className="hover:underline  hover:text-theme-color ">Users</button>
              </Link>
            </div>
            <button
              onClick={handleCreateRole}
              className="border shadow-btnshd bg-theme-color text-white px-4 py-2 rounded text-sm">
              Create New Role
            </button>
          </div>
        </div>
        {loading ? <GlobalLoader />
          :
          <>
            <div className="flex flex-wrap">
              {userRoles.length > 0 ? userRoles.map((item) => (
                <div className="bg-white p-[1rem] rounded-xl w-[363px] mr-[24px] mb-[16px]">
                  <h1 class="text-slate-900 text-xl pb-5 font-bold leading-normal">{item?.roleName}</h1>
                  <p className="text-slate-500 text-[13px] font-semibold leading-tight">Total users with this role: {0}</p>
                  <div className='mt-6 flex justify-between'>
                    <button name='delete' className="border border-theme-color text-theme-color align-middle items-center rounded text-sm w-[160px] h-[36px]" onClick={(e) => handleRoleAction(e, item._id)}>Delete</button>
                    <button name='edit' className="border shadow-btnshd bg-theme-color text-white align-middle items-center rounded text-sm w-[160px] h-[36px]" onClick={(e) => handleRoleAction(e, item._id)}>Edit</button>
                  </div>
                </div>
              )) : <NoDataModal />}
            </div>
          </>
        }
      </div>
      <Popover isPopOpen={isPopOpen} handleAction={handlePopAction} />
      <NewRole isOpen={isModalOpen} onClose={toggleModal} userRoleInfo={roleInfo} />
    </>
  )
}

export default UserRoles
